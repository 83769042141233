import React, { useState } from 'react'
import { navigate } from 'gatsby'

import { handleLogin, isLoggedIn } from '../services/auth'
import { Root } from '../components/Root'
import {
  Button,
  Container,
  Input,
  Heading,
  Grid,
  Box,
  CenterVertically,
} from '../system/components'

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [credentialsFailure, setCredentialsFailure] = useState(false)

  const naviateToApp = () => {
    navigate('/app/home')
    return null
  }

  const handleSubmit = e => {
    e.preventDefault()
    handleLogin({
      username,
      password,
      onSuccess: () => {
        naviateToApp()
      },
      onFailure: () => setCredentialsFailure(true),
    })
  }

  return isLoggedIn() ? (
    naviateToApp()
  ) : (
    <Root>
      <CenterVertically>
        <Container>
          <Box center width={{ xs: 1, sm: 7 / 8, md: 3 / 4 }}>
            <Heading as="h1" pb={2}>
              Finance Manager
            </Heading>
            <Heading as="h3">This application has restricted access.</Heading>
            <form onSubmit={e => handleSubmit(e)}>
              <Grid gap>
                <Box width={{ xs: 1, sm: 1 / 2 }}>
                  <label>
                    <div>Username:</div>
                    <Input
                      width={1}
                      type="text"
                      name="username"
                      value={username}
                      onChange={e => setUsername(e.target.value)}
                    />
                  </label>
                </Box>
                <Box width={{ xs: 1, sm: 1 / 2 }}>
                  <label>
                    <div>Password:</div>
                    <Input
                      width={1}
                      type="password"
                      name="password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </label>
                </Box>
                <Box width={1}>
                  <Button width={1} type="submit">
                    Login
                  </Button>
                  <Box width={1} pt={2} color="red">
                    {credentialsFailure ? (
                      <p>Credentials incorrect</p>
                    ) : (
                      <p>&nbsp;</p>
                    )}
                  </Box>
                </Box>
              </Grid>
            </form>
          </Box>
        </Container>
      </CenterVertically>
    </Root>
  )
}

export default Login
